// usePasswordResetForm.js

import { useFormik } from "formik";
import * as Yup from 'yup';
import { LgbtToast } from "../../../components/LgbtToast/LgbtToast";
import { useResetPasswordMutation } from "../api/dashboardApiSlice";

const initialValues = {
    old_password: '',
    new_password: '',
    confirm_password: '',
};

const validationSchema = Yup.object({
    old_password: Yup.string().required(),
    new_password: Yup.string().required(),
    confirm_password: Yup.string().required(),
});

function usePasswordResetForm() {
    const [submitPasswordCredentials, { isError, error, isLoading, isFetching, success }] = useResetPasswordMutation();

    const passwordResetForm = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setStatus, resetForm, setFieldError }) => {
            if (values.new_password === values.confirm_password) {
                const response = await submitPasswordCredentials(values);
                if (response.error || isError) {
                    LgbtToast({ message: response.error.data.error, type: 'error' });
                } else {
                    LgbtToast({ message: response.data.success, type: 'success' });
                    resetForm();
                }
            } else {
                LgbtToast({ message: 'Passwords not matching', type: 'error' });
            }
        },
    });
    return { passwordResetForm, isError };
}

export default usePasswordResetForm;
