import { api } from '../../../utils/api';
import authTokenHeader from '../../../common/functions/authTokenHeader';


const jobPostApi = api.injectEndpoints({
  endpoints: (builder) => ({
    postJob: builder.mutation({
      query: (body) => {
        return {
          'url': `/jobmanager/jobs/`,
          'method': 'post',
          'body': body,
          'headers': {
            ...authTokenHeader(),
          }
        }
      },
      invalidatesTags: ['singleJobForEdit', 'Jobs']
    }),
    updateJob: builder.mutation({
      query: ({ body, slug }) => {
        return {
          'url': `/jobmanager/job/${slug}/`,
          'method': 'put',
          'body': body,
          'headers': {
            ...authTokenHeader(),
          }
        }
      },
      invalidatesTags: ['singleJobForEdit', 'Jobs', 'getSkills']
    }),
    getSingleJobForEdit: builder.query({
      query: (jobId) => `/jobmanager/job/edit-details/${jobId}/`,
      providesTags: ['singleJobForEdit']
    }),
    getSkillSuggestions: builder.query({
      query: () => '/jobmanager/skills/',
      transformResponse: (rawData) => {
        if (Array.isArray(rawData)) {
          return rawData.map(item => ({
            id: item.id,
            name: item.name,
          }));
        }
        if (rawData.results) {
          return rawData.results.map(item => ({
            id: item.id,
            name: item.name,
          }));
        }
        return [];
      },
      providesTags: ['getSkills']
    }),
    getSalaryTypes: builder.query({
      query: () => {
        return {
          url: `/jobmanager/job/salary-types/`,
          headers: {
            ...authTokenHeader(),
          }
        }
      },
    }),
    getSalaryRange: builder.query({
      query: () => '/jobmanager/job/salary-amount',
    }),
    getContractTypes: builder.query({
      query: () => {
        return {
          url: `/jobmanager/job/contract-types/`,
        }
      },
      transformResponse: (rawData) => {
        if (!rawData.results) return [];
        return rawData.results.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      }
    }),
    getSectors: builder.query({
      query: () => {
        return {
          url: `/jobmanager/sectors/`,
        }
      },
      transformResponse: (rawData) => {
        if (!rawData.results) return [];
        return rawData.results.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      }
    }),
    getLocationSuggestions: builder.query({
      query: (query) => ('/jobmanager/search/autocomplete_location/?location=' + query),
    }),
  }),
})

export const {
  usePostJobMutation,
  useUpdateJobMutation,
  useGetSingleJobForEditQuery,
  useGetSkillSuggestionsQuery,
  useGetSalaryTypesQuery,
  useGetSalaryRangeQuery,
  useGetContractTypesQuery,
  useGetSectorsQuery,
  useGetLocationSuggestionsQuery,
} = jobPostApi