import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

function RequireAuth({ children }) {

    const authState = useSelector((state) => state.auth)
    const location = useLocation();
    
    if (!authState.isAuthenticated || authState.accessToken === null || authState.accessToken === undefined) {
        return <Navigate to="/login" state={{ path: location.pathname }} replace />
    }

    return (
        <>{children}</>
    )
}

export default RequireAuth
