import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    accessToken: null,
    refreshToken: null,
  },
  reducers: {
    login: (state, action) => {
      Cookies.set('access', action.payload.access)
      Cookies.set('refresh', action.payload.refresh)
      Cookies.set('isAuthenticated', true)

      state.isAuthenticated = true;
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
    },
    logout: (state) => {
      Cookies.remove('access')
      Cookies.remove('refresh')
      Cookies.remove('isAuthenticated')

      state.isAuthenticated = false;
      state.accessToken = null;
      state.refreshToken = null;
    },
    updateAccess: (state, action) => {
      Cookies.set('access', action.payload.access)
      Cookies.set('isAuthenticated', true)

      state.accessToken = action.payload.access
      state.isAuthenticated = true;
    },
    initAuth: (state, action) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
    },
  },
});


export const { login, logout, updateAccess, initAuth, adminLogin } = authSlice.actions;

export default authSlice.reducer;