import React from 'react'
import styles from './NameField.module.scss'

function NameField({ form }) {
    return (
        <>
            <section className={`${styles.nameFieldWrapper} row`}>
                <div className={`${styles.title} col-md-3`}>
                    <h2>Full Name*</h2>
                </div>
                <div className={`${styles.inputWrapper} col-md-9`}>
                    <div className={styles.fieldWrap}>
                        <label htmlFor="full_name">Full Name*</label>
                    </div>
                    <input
                        id='full_name'
                        type="text"
                        value={form.values.full_name}
                        onChange={form.handleChange}
                    />
                </div>
                <div className={`${styles.inputWrapper} ${styles.email} col-md-9`}>
                    <div className={styles.fieldWrap}>
                        <label htmlFor="email">Email Address*</label>
                    </div>
                    <input
                        id='email'
                        type="email"
                        value={form.values.email}
                        onChange={form.handleChange}
                    />
                </div>
            </section>
        </>
    )
}

export default NameField
