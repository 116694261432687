import { useFormik } from 'formik'
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from 'yup'
import { useLoginAttemptMutation } from '../api/authApiSlice';
import {login} from '../hooks/authSlice'
import { useDispatch } from 'react-redux';
import { api } from '../../../utils/api';

function useLoginForm(setFormError) {

    const navigate = useNavigate();
    const loc = useLocation();
    const queryParams = new URLSearchParams(loc.search);
    const username = queryParams.get('username');
    const password = queryParams.get('password');
    const location = useLocation();
    const dispatch = useDispatch();

    const redirect = location.state?.path || '/';

    const initialValues = {
        username: username ?? '',
        password: password ?? ''
    }
    const validationSchema = Yup.object({
        'username': Yup.string().required('Required'),
        'password': Yup.string().required('Required'),
    })

    const [attemptLogin, {isFetching, isLoading}] = useLoginAttemptMutation()

    const loginForm = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                const data = await attemptLogin(values);
                if(data === undefined){
                    setFormError('Something went wrong! Please try again later')
                    return;
                }
                if(data.error){
                    if(data.error.status === 401 && data.error?.data.detail != undefined)
                        setFormError(data.error?.data.detail);
                    else
                        setFormError('Something went wrong! Please try again later')
                    return;
                }
                dispatch(login(data.data))
                loginForm.resetForm()
                setTimeout(()=>{
                    dispatch(api.util.invalidateTags(['HeaderNav']))
                },300)
                navigate(redirect, {replace: true})
            } catch (error){
                console.log('Something went wrong!',error);
            }
        }
    })


    return { loginForm, isFetching, isLoading }
}

export default useLoginForm