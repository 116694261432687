import { api } from '../../../utils/api';
import authTokenHeader from '../../../common/functions/authTokenHeader';

const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        loginAttempt: builder.mutation({
            query: ({username, password}) => {
              return {
                'url':`/user/token/`,
                'method': 'post',
                'body': {username, password},
              }
            },
            invalidatesTags: ['HeaderNav','Jobs','purchasedPackages','jobStatus','profile']
        }),
        refreshLogin: builder.mutation({
          query: ({refresh})=>{
            return {
              'url': '/user/token/refresh/',
              'method': 'post',
              'body': {refresh},
            }
          }
        }),
        verifyLogin: builder.mutation({
          query: ()=> {
            return {
              'url': '/user/token/validator/',
              'method': 'get',
              'headers': {
                ...authTokenHeader(),
              }
            }
          }
        }),
    }),
    extraOptions: {
      includeCreds: true,
    }
})

export const { useLoginAttemptMutation, useRefreshLoginMutation, useVerifyLoginMutation} = authApi
export const { endpoints: { loginAttempt, refreshLogin, verifyLogin } } = authApi