import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'

import {useVerifyLoginMutation} from '../api/authApiSlice'
import {initAuth} from '../hooks/authSlice'
import {logout} from '../hooks/authSlice'

function useAuthInit() {

	const [verifyLogin] = useVerifyLoginMutation()
	const dispatch = useDispatch()

	return async ()=>{
		const isAuthenticated = Cookies.get('isAuthenticated');
		const access = Cookies.get('access')
		const refresh = Cookies.get('refresh')


    if(isAuthenticated !== 'true' || access == undefined || access == null || refresh == undefined || refresh == null){
		dispatch(logout());
		return false;
    }
    
    dispatch(initAuth({access, refresh, isAuthenticated: true}));

    try {
      	const data = await verifyLogin();
		if(!data?.error){
			return true;
		}
    } catch (error){
      	console.log('Something went wrong!',error);
    }
  }
}

export default useAuthInit
