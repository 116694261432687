import React, { useEffect, useState } from 'react';
import { APIProvider, Map, Marker, useMarkerRef, InfoWindow } from '@vis.gl/react-google-maps';
import styles from './JobLocMap.module.scss'

function JobLocMap({ lat, lng, loc }) {
    const [marker] = useMarkerRef();
    const [selectedLocation, setSelectedLocation] = useState(null);

    useEffect(() => {
        if (!marker) {
            return;
        }
    }, [marker]);

    const handleMarkerClick = (location) => {
        setSelectedLocation(location);
    };

    const handleInfoWindowClose = () => {
        setSelectedLocation(null);
    };

    if (lat && lng) {
        return (
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                <Map
                    className={styles.mapBody}
                    zoom={8}
                    center={{ lat, lng }}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                >
                    {loc.map(
                        (location, index) => {
                            return (<Marker
                                key={index}
                                position={{ lat: location.latitude, lng: location.longitude }}
                                onClick={() => handleMarkerClick(location)}
                            />)
                        }
                    )}

                    {selectedLocation && (
                        <InfoWindow
                            position={{
                                lat: selectedLocation.latitude,
                                lng: selectedLocation.longitude,
                            }}
                            onCloseClick={handleInfoWindowClose}
                        >
                            <div>
                                <h3>{selectedLocation.name}</h3>
                            </div>
                        </InfoWindow>
                    )}
                </Map>
            </APIProvider>
        )
    }
}

export default JobLocMap
