import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { MdError } from "react-icons/md";
import useLoginForm from '../hooks/useLoginForm'
import RoundLoader from '../../../components/Loader/RoundLoader'
import bannerImage from '../../../assets/images/generic-banner.png'
import styles from '../styles/Login.module.scss'


function Login() {

    const [formError, setFormError] = useState('');
    const [unFocused, setUnFocused] = useState(null);
    const [pwfocused, setPwFocused] = useState(null);

    const useloginFormMemoized = useCallback(useLoginForm, [setFormError])
    const { loginForm, isFetching, isLoading } = useloginFormMemoized(setFormError)
    const siteSetting = useSelector(state => state.site)


    let unErrorCls = loginForm.touched.username && loginForm.errors.username ? styles.inputError : null
    let pwErrorCls = loginForm.touched.password && loginForm.errors.password ? styles.inputError : null

    const handleFocus = (e) => {
        if (e.target.name === 'username') {
            setUnFocused(styles.inputFocus)
        }
        if (e.target.name === 'password') {
            setPwFocused(styles.inputFocus)
        }
    }
    const handleBlur = (e) => {
        if (e.target.name === 'username') {
            setUnFocused(null);
        }
        if (e.target.name === 'password') {
            setPwFocused(null);
        }
        setFormError('');
    }

    return (
        <section className={styles.login} style={{ backgroundImage: `url(${siteSetting.bannerImage ?? bannerImage})` }}>
            <div className={`${styles.forOuter} container`}>
                <div className={styles.formWrap}>
                    <h1>Recruiter Sign In</h1>
                    <form action="" onSubmit={loginForm.handleSubmit}>
                        {formError && !(isFetching || isLoading) ? (
                            <div className={styles.textError}>{formError}</div>
                        ) : null}
                        <div className={`${styles.inputWarp} ${unErrorCls} ${unFocused}`}>
                            <input
                                type="text"
                                name="username"
                                value={loginForm.values.username}
                                onChange={loginForm.handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                id="username"
                                placeholder="Username" />
                            <MdError className={styles.errorIcon} />
                        </div>
                        <div className={`${styles.inputWarp} ${pwErrorCls} ${pwfocused}`}>
                            <input
                                type="password"
                                name="password"
                                value={loginForm.values.password}
                                onChange={loginForm.handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                id="password"
                                placeholder="Password" />
                            <MdError className={styles.errorIcon} />
                        </div>
                        {/* <Link className={styles.forgotPw} to="/">Forgot Password?</Link> */}
                        <div className={styles.submitWrap}>
                            {
                                (isFetching || isLoading) ?
                                    <RoundLoader className={styles.loginLoader} /> :
                                    <button type="submit" className={styles.submitBtn}>Login</button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Login
