import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { useJobAlertMutation } from "../api/jobAlertApiSlice";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as Yup from 'yup'
import { LgbtToast } from "../../../components/LgbtToast/LgbtToast";


const initialValues = {
    full_name: "",
    job_title_keyword: "",
    email: "",
    location: "",
    work_from_home: false,
    salary_amount: 0,
    minimum_salary: 0,
    maximum_salary: 0,
    contract_type: [],
    sector: [],
    // recaptcha: '',
    alert_frequency: 1,
    distance: 0,
}

const validationSchema = Yup.object({
    'full_name': Yup.string().required('Name is required'),
    'job_title_keyword': Yup.string().required('Job title is required'),
    'email': Yup.string().email('Invalid email format').required('Email is required'),
    'location': Yup.string(),
    'work_from_home': Yup.bool(),
    'contract_type': Yup.array(),
    'salary_amount': Yup.number(),
    'minimum_salary': Yup.number(),
    'maximum_salary': Yup.number(),
    'sector': Yup.array(),
    // Desabled to ignore recaptcha error
    // 'recaptcha': Yup.string().required('Execute recaptcha not yet available'),
    'alert_frequency': Yup.number(),
    'distance': Yup.number(),
})

function useJobAlertForm() {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [AlertResponseMessage, setAlertResponseMessage] = useState("");
    const [submitAlert, { isFetching, isLoading, isError, Error }] = useJobAlertMutation();

    // useEffect(() => {
    //     handleReCaptchaVerify();
    // }, [executeRecaptcha]);

    const handleReCaptchaVerify = async () => {
        if (!executeRecaptcha) {
            return;
        }
        const token = await executeRecaptcha("alert_form_submission");
        jobAlertForm.setFieldValue("recaptcha", token);
    };

    const jobAlertForm = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setStatus, resetForm, setFieldError }) => {
            values.minimum_salary = values.salary_amount
            if (values.minimum_salary > values.maximum_salary) {
                // Swap the values of minimum_salary and maximum_salary
                values = {
                    ...values,
                    minimum_salary: values.maximum_salary,
                    maximum_salary: values.minimum_salary,
                };
            }

            const response = await submitAlert(values);
            if (response.error) {
                LgbtToast({ message: response.error.status, type: 'error' })
                setAlertResponseMessage('Alert not sent')
            } else {
                LgbtToast({ message: 'Alert sent successfully', type: 'success' })
                setAlertResponseMessage('Alert sent successfully')
                resetForm();
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
    });

    return { jobAlertForm, isFetching, isLoading, isError, Error, AlertResponseMessage };
}

export default useJobAlertForm;
