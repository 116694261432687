import { api } from '../../../utils/api'
import authTokenHeader from '../../../common/functions/authTokenHeader'

const dashboardApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getProfile: builder.query({
            query: () => {
                return {
                    url: `/user/my/profile`,
                    headers: {
                        ...authTokenHeader(),
                    }
                }
            },
            providesTags: ['profile']
        }),
        getMyJobs: builder.query({
            query: (args) => {

                let { currentEmployer, filter, sort, search } = args;
                if(currentEmployer == undefined){
                    return false
                }
                let query = `/jobmanager/my/jobs/?employer=${currentEmployer}`;

                if (filter) {
                    query += `&filter_by=${filter}`
                }
                if (sort) {
                    query += `&sort_by=${sort}`
                }
                if (search) {
                    query += `&s=${search}`
                }
                return {
                    url: query,
                    headers: {
                        ...authTokenHeader(),
                    }
                }
            },
            providesTags: ['Jobs'],
        }),
        getMyEmployers: builder.query({
            query: () => {
                return {
                    url: `/jobmanager/my/employers`,
                    headers: {
                        ...authTokenHeader(),
                    }
                }
            },
            transformResponse: (rawData) => {
                if (!rawData) return [];
                return rawData.map((item) => ({
                    value: item.id,
                    label: item.name,
                }))
            },
            providesTags: ['Employers'],
        }),
        getJobPackages: builder.query({
            query: () => {
                return {
                    url: `/jobmanager/my/purchased-packages`,
                    headers: {
                        ...authTokenHeader(),
                    }
                }
            },
            providesTags: ['purchasedPackages'],
        }),
        getJobStatuses: builder.query({
            query: () => {
                return {
                    url: `/jobmanager/job-statuses`,
                }
            },
            providesTags: ['jobStatus'],
        }),
        deleteJob: builder.mutation({
            query: (slug) => {
                return {
                    url: `/jobmanager/job/${slug}`,
                    method: 'DELETE',
                    headers: {
                        ...authTokenHeader(),
                    }
                }
            },
            invalidatesTags: ['Jobs'],
        }),
        resetPassword: builder.mutation({
            query: (body) => {
                return {
                    url: `/user/recruiter/reset-password/`,
                    method: 'post',
                    body: body,
                }
            },
        }),
    }),
})

export const {
    useGetProfileQuery,
    useGetMyJobsQuery,
    useGetJobPackagesQuery,
    useGetJobStatusesQuery,
    useGetMyEmployersQuery,
    useDeleteJobMutation,
    useResetPasswordMutation
} = dashboardApi