import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaPlay } from "react-icons/fa";
import './TextToSpeech.scss';

const TextToSpeech = () => {
  const [speaking, setSpeaking] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
  const [selectedText, setSelectedText] = useState('');
  const [voices, setVoices] = useState([]);
  const site = useSelector(state => state.site);
  let hasEnabledVoice = false;

  const isIPhoneOrIPad = () => {
    return /iPhone|iPad|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const populateVoiceList = () => {
    const availableVoices = window.speechSynthesis.getVoices();
    if (availableVoices.length === 0) {
      setTimeout(populateVoiceList, 100); // Retry after 100ms if no voices found
    } else {
      console.log("Available voices:", availableVoices);
      setVoices(availableVoices);

      // Log available voices on iOS
      if (isIPhoneOrIPad()) {
        console.log("iOS Available voices:", availableVoices);
      }
    }
  };

  useEffect(() => {
    populateVoiceList();
    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = populateVoiceList;
    }
  }, []);

  const handleSelection = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();
      const buttonTop = rect.bottom - bodyRect.top + 5;
      const buttonLeft = rect.left + rect.width / 2;
      setButtonPosition({ top: buttonTop, left: buttonLeft });
      const text = selection.toString().trim();
      setSelectedText(text);
    } else {
      setSelectedText('');
    }
  };

  const handlePlayButtonClick = () => {
    if (selectedText) {
      readText(selectedText);
    }
  };

  const readText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);

    let selectedVoice;

    if (isIPhoneOrIPad()) {
      // Target the specific voice for iPhone/iPad
      selectedVoice = voices.find(voice => voice.voiceURI === "com.apple.voice.compact.en-US.Samantha" && voice.name === "Samantha" && voice.lang === "en-US");
      if (!selectedVoice) {
        // Fallback if the specific voice is not available
        selectedVoice = voices.find(voice => voice.lang === 'en-GB') || voices[0];
        console.log("no samantha voice")
      }
    } else {
      // General selection for other devices
      selectedVoice = voices.find(voice => voice.lang === 'en-GB' && voice.name.includes('Female')) || voices.find(voice => voice.lang === 'en-GB') || voices[0];
      console.log("this for other devices")
    }

    utterance.voice = selectedVoice;
    utterance.volume = 1;
    utterance.rate = 0.8;
    utterance.pitch = 1;

    utterance.onend = () => {
      setSpeaking(false);
      if (isIPhoneOrIPad()) {
        window.speechSynthesis.cancel(); // Reset the speech synthesis to avoid the iOS issue
      }
    };

    utterance.onerror = (e) => {
      console.error('SpeechSynthesisUtterance.onerror', e);
      setSpeaking(false);
    };

    if (selectedVoice) {
      console.log("Using voice:", selectedVoice.name);
    } else {
      console.log("No suitable voice found, using default.");
    }

    window.speechSynthesis.speak(utterance);
    setSpeaking(true);
  };

  useEffect(() => {
    const enableVoice = () => {
      if (hasEnabledVoice) return;
      const lecture = new SpeechSynthesisUtterance('');
      lecture.volume = 1;
      speechSynthesis.speak(lecture);
      hasEnabledVoice = true;
    };

    document.addEventListener('click', enableVoice);

    return () => {
      document.removeEventListener('click', enableVoice);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('selectionchange', handleSelection);

    return () => {
      document.removeEventListener('selectionchange', handleSelection);
    };
  }, []);

  return (
    <>
      {selectedText && (
        <button
          className={`speech-button ${speaking ? 'speaking' : ''}`}
          style={{ top: buttonPosition.top, left: buttonPosition.left, backgroundColor: site.primaryColor }}
          onClick={handlePlayButtonClick}
          disabled={speaking}
        >
          <FaPlay />
        </button>
      )}
    </>
  );
};

export default TextToSpeech;
