import React, {useState, useEffect, useRef} from 'react'
import {useGetLocationSuggestionsQuery} from '../../api/jobCreateApiSlice'
import RoundLoader from '../../../../components/Loader/RoundLoader'
import styles from './SuggestionBox.module.scss'

function SuggestionBox({keyword, form, visibilty, setVisibility}) {

    const {data, error, isLoading, isFetching} = useGetLocationSuggestionsQuery(keyword)
    const suggestionRef = useRef(null);
    
    const handleClick = (e) => {
        form.setFieldValue('location_string', e.target.innerText)
        form.setFieldValue('location', e.target.innerText)
        setVisibility(false)
    }

    useEffect(() => {
        document.addEventListener('click', handleSuggestionBoxVisibility)
        return () => {
            document.removeEventListener('click', handleSuggestionBoxVisibility);
        };
    });

    const handleSuggestionBoxVisibility = (e) => {
        if (
            suggestionRef.current &&
            visibilty &&
            !suggestionRef.current.contains(e.target)
        ) {
            setVisibility(false);
        }
    }

    if((isLoading || isFetching) && visibilty){
        return <div>
            <RoundLoader className={styles.whiteLoader}/>
        </div>
    }

    return (data != undefined && data.length > 0 && visibilty) ? 
        (<ul className={styles.suggestionBox} ref={suggestionRef}>
            {data && data.map((item, index) => {
                return (
                    <li key={index} className={styles.suggestionItem} onClick={handleClick}>{item}</li>
                )
            })}
        </ul>) : null;
}

export default SuggestionBox
