import React, { useState } from "react";
import FieldError from "../FieldError/FieldError";
import SuggestionBox from "../SuggestionBox/SuggestionBox";
import styles from "./JobLocationFields.module.scss";
import Select from 'react-select';

function JobLocationFields({ form, type, whichForm, hintText, locationPlaceholder }) {
    const [selectedDistance, setSelectedDistance] = useState(null); // State to hold the selected distance value
    const [suggestionVisibility, setSuggestionVisibility] = useState(true);

    const distance = [
        { value: 0, label: '0 miles' },
        { value: 1, label: '1 mile' },
        { value: 3, label: '3 miles' },
        { value: 5, label: '5 miles' },
        { value: 10, label: '10 miles' },
        { value: 15, label: '15 miles' },
        { value: 20, label: '20 miles' },
        { value: 30, label: '30 miles' },
        { value: 50, label: '50 miles' },
    ];

    const handleDistanceChange = (selectedOption) => {
        setSelectedDistance(selectedOption);
        form.setFieldValue('distance', selectedOption.value);
    };

    return (
        <section className="jobLocFieldsWrap fieldGroupWrap">
            <div className="row">
                <div className="fieldHead col-md-3">
                    <h2>Job location</h2>
                </div>
                <div className="jobFields col-md-9">
                    <div className="row">
                        <div className="col-md-12">
                            {
                                whichForm !== 'jobAlertForm' ?
                                    <div className="fieldWrap">
                                        <div className={`${styles.labelBox}`}>
                                            <input
                                                className={styles.checkbox}
                                                type="checkbox"
                                                name="is_remote_job"
                                                checked={form.values.is_remote_job}
                                                onChange={form.handleChange}
                                            />
                                            <label
                                                className={styles.label}
                                                htmlFor="jobAddress"
                                            >This is a remote job</label>
                                        </div>
                                    </div>
                                    : ''
                            }
                            <div className="fieldWrap">
                                <div className="labelBox">
                                    <label htmlFor="jobAddress">Address</label>
                                </div>
                                {
                                    whichForm === 'jobAlertForm' ?
                                        <>
                                            <input
                                                id="location"
                                                type="text"
                                                name="location"
                                                placeholder={locationPlaceholder}
                                                value={form.values.location}
                                                onChange={(e) => {
                                                    form.setFieldValue('location', e.target.value)
                                                    setSuggestionVisibility(true)
                                                }}
                                                autoComplete="off"
                                            />
                                            {form.values.location && <SuggestionBox keyword={form.values.location} form={form} visibilty={suggestionVisibility} setVisibility={setSuggestionVisibility} suggestion='location' />}
                                            <FieldError error={form.errors.location} touched={form.touched.location} />
                                        </>
                                        :
                                        <>
                                            <input
                                                id="location"
                                                type="text"
                                                name="location_string"
                                                value={form.values.location_string}
                                                onChange={(e) => {
                                                    form.setFieldValue('location_string', e.target.value)
                                                    setSuggestionVisibility(true)
                                                }}
                                                autoComplete="off"
                                            />
                                            {form.values.location_string && <SuggestionBox keyword={form.values.location_string} form={form} visibilty={suggestionVisibility} setVisibility={setSuggestionVisibility} suggestion='location' />}
                                            <FieldError error={form.errors.location_string} touched={form.touched.location_string} />
                                        </>
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            {
                                whichForm === 'jobAlertForm' &&
                                <div className="fieldWrap">
                                    <label htmlFor="">Distance</label>
                                    <Select
                                        isClearable={false}
                                        isSearchable={false}
                                        options={distance}
                                        value={selectedDistance}
                                        onChange={handleDistanceChange}
                                    />
                                </div>
                            }
                        </div>
                        {
                            whichForm === 'jobAlertForm' ?
                                <div className={styles.wfh}>
                                    <div className={`${styles.checkboxWrapper}`}>
                                        <input
                                            type="checkbox"
                                            id="work_from_home"
                                            name="work_from_home"
                                            checked={form.values.work_from_home}
                                            onChange={event => {
                                                const { checked } = event.target;
                                                form.setFieldValue('work_from_home', checked);
                                            }}
                                        />
                                    </div>
                                    <div className={styles.checkboxTitle}>
                                        <h4 htmlFor="work_from_home">Include work from home jobs?</h4>
                                    </div>
                                </div> : ''
                        }
                        {
                            hintText ?
                                <div className={styles.locationLabelBox}>
                                    <label htmlFor="jobAddress">Postcode*</label>
                                    <div dangerouslySetInnerHTML={{ __html: hintText }}/>
                                </div>
                                : ''
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export default JobLocationFields;
