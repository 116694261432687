import React from 'react'
import styles from './LgbtDecorLine.module.scss'

function LgbtDecorLine(props) {
    return (
        <div className={`${styles.decorWrapper} ${props.className}`}>
            <div className={styles.red}></div>
            <div className={styles.orange}></div>
            <div className={styles.yellow}></div>
            <div className={styles.green}></div>
            <div className={styles.blue}></div>
            <div className={styles.purple}></div>
            <div className={styles.violet}></div>
        </div>
    )
}

export default LgbtDecorLine