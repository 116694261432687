import styles from './JobContent.module.scss'


function JobContent({ content }) {
  content = content.replace(/\n/g, '<br/>');

  return (
    <div className={styles.jobContent} dangerouslySetInnerHTML={{ __html: content }} />
  )
}

export default JobContent
