import React, { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useGetProfileQuery, useGetMyJobsQuery, useGetJobsDataQuery, useGetMyEmployersQuery } from './api/dashboardApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from '../GlobalLoader/globalLoaderSlice';
import { setEmployer } from './dashboardSlice'
import styles from './styles/Dashboard.module.scss';
import axios from 'axios';
import authTokenHeader from '../../common/functions/authTokenHeader';
import Select from 'react-select'

function Dashboard() {
    const [selectedEmployer, setSelectedEmployer] = useState("");

    const dashboard = useSelector(state => state.dashboard)
    const currentEmployer = dashboard.employer?.value

    const { data, isLoading, isFetching } = useGetMyJobsQuery({currentEmployer});

    const { data: user } = useGetProfileQuery();
    const { data: employerData, isLoading: employerIsLoading, isFetching: employerIsFetching } = useGetMyEmployersQuery();
    const [active, setActive] = useState('my-jobs');
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const dispatch = useDispatch();
    const loaderId = { id: 'dashboard' };
    const [isMobile, setIsMobile] = useState(false);

    const date = new Date()

    const handleSelect = (selectedOption) => {
        dispatch(setEmployer(selectedOption))
    }

    useEffect(() => {
        if (employerData) {
            dispatch(setEmployer(employerData[0]))
        }
    }, [employerData])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        if (isLoading || isFetching) {
            dispatch(showLoader(loaderId));
        } else {
            dispatch(hideLoader(loaderId));
        }
    }, [isLoading, isFetching, dispatch]);

    const getJobDataFile = () => {
        axios({
            url: baseUrl + '/jobmanager/download-jobs-csv/',
            method: 'GET',
            responseType: 'blob',
            headers: {
                ...authTokenHeader(),
            }
        })
            .then(response => {
                const fileName = user.username + '_job_export_' + date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear() + '.csv';
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);

                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(error => console.error('Error fetching file:', error));
    }

    const handleClick = (key) => {
        setActive(key);
    }

    return (
        <section className={styles.dashboard}>
            <div className={`${styles.dashboardWrap}`}>
                <div className={styles.head}>
                    <div className={styles.titleAndButton}>
                        {
                            !isMobile ?
                                <h1>{user?.username} - Dashboard</h1>
                                : <h1>{user?.username} Dashboard</h1>
                        }
                        <a className={styles.jobPostBtn} href={`/post-a-job/?employer=${dashboard?.employer?.value}`} target='_blank' rel="noopener noreferrer">Post your listing</a>
                    </div>
                    {
                        employerData?.length > 1 ?
                            <Select
                                className={styles.dashboardSelect}
                                value={dashboard.employer}
                                options={employerData}
                                onChange={handleSelect}
                            />
                            : ''
                    }
                </div>
                <div className={styles.subHead}>
                    <ul className={styles.links}>
                        <li>
                            <Link className={`${styles.link} ${active === 'my-jobs' ? styles.active : ''}`} onClick={() => { handleClick('my-jobs') }} to="/dashboard/my-jobs">Manage Jobs</Link>
                        </li>
                        <li>
                            <Link className={`${styles.link} ${active === 'my-packages' ? styles.active : ''}`} onClick={() => { handleClick('my-packages') }} to="/dashboard/my-packages">Purchased Packages</Link>
                        </li>
                        <li className={styles.downloadBtn}>
                            <button className={`${styles.link} ${styles.downloadJobData}`} onClick={getJobDataFile}>Download Job Data</button>
                        </li>
                        <li>
                            <Link className={`${styles.link} ${active === 'reset-password' ? styles.active : ''}`} onClick={() => { handleClick('reset-password') }} to="/dashboard/reset-password">Reset Password</Link>
                        </li>
                    </ul>
                    <div>
                        {data && data.length > 0 && data[0]?.logo && (
                            <img src={data[0].logo.file} alt="Logo" />
                        )}
                    </div>
                </div>
                <div className={styles.downloadDataWrapper}><span>Your listings are below</span> <Link className={`${styles.link} ${styles.downloadJobData}`} onClick={getJobDataFile}>Download Job Data</Link></div>
                <div className={`tableCntWrap ${styles.tableWrap}`}>
                    <Outlet />
                </div>
            </div>
        </section>
    )
}

export default Dashboard
