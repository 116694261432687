import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { LiaTimesSolid } from 'react-icons/lia';
import { api } from '../../utils/api'

import { logout } from '../../features/Auth/hooks/authSlice';
import Button from '../Button/Button'
import styles from './HeaderNav.module.scss'
import { hideLoader, showLoader } from '../../features/GlobalLoader/globalLoaderSlice';

function HeaderNav({ menu, logging }) {

  const [isVisible, setVisibilty] = useState(0);
  const navRef = useRef(null);
  const navOpenerRef = useRef(null);
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch();

  const handleMenuClose = (e) => {
    if (
      navRef.current &&
      isVisible &&
      !navRef.current.contains(e.target) &&
      e.target !== navOpenerRef.current
    ) {
      setVisibilty(0);
    }
  }

  const logOutHandler = (e) => {
    dispatch(showLoader({ id: 'logout' }))
    setTimeout(() => {
      dispatch(logout());
      dispatch(api.util.invalidateTags(['HeaderNav', 'Jobs', 'purchasedPackages', 'jobStatus', 'profile']))
      dispatch(hideLoader({ id: 'logout' }))
    }, 500);
    setVisibilty(0);
  }

  useEffect(() => {
    document.addEventListener('click', handleMenuClose)
    return () => {
      document.removeEventListener('click', handleMenuClose);
    };
  });


  return (
    <div className={styles.navWrapper}>
      <button className={styles.navToggler} onClick={() => { setVisibilty(1) }} ref={navOpenerRef}>
        <span className={styles.navTogglerIcon}></span>
      </button>
      <div className={styles.navigationBar} ref={navRef} style={isVisible === 1 ? { transform: "translateX(0%)" } : {}}>
        <span className={styles.navClose} onClick={() => { setVisibilty(0) }}>
          <LiaTimesSolid />
        </span>
        <ul className={styles.navLinks}>
          {menu?.MenuLinks &&
            menu.MenuLinks.map((item, index) => (
              <li className={styles.navItem} key={index}>
                <NavLink to={item.link} className={`${styles.navLink} ${item.primary_color ? styles.primaryColor : ''}`} onClick={() => { setVisibilty(0); }}>
                  {item.label}
                </NavLink>
              </li>
            ))}
        </ul>
        {
          auth.isAuthenticated ?
            (<div className={styles.authBtn}>
              <button className={styles.logout} type="primary" onClick={(e) => { logOutHandler(); }}>
                Logout
              </button>
            </div>
            ) :
            logging && (
              <div className={styles.authBtn}>
                <Button className={styles.signUp} type="primary" to='/login' onClick={() => { setVisibilty(0); }}>
                  {logging.label}
                </Button>
              </div>
            )
        }
      </div>
    </div>
  )
}

export default HeaderNav