import { api } from '../../../utils/api';

const jobAlertApi = api.injectEndpoints({
    endpoints: (builder) => ({
        jobAlert: builder.mutation({
            query: (body) => {
                return {
                    'url': `/jobalerts/job-alert`,
                    'method': 'post',
                    'body': body,
                }
            },
        }),
        jobAlertFrequencyOption: builder.query({
            query: (body) => {
                return {
                    'url': `/jobalerts/frequency-options`,
                }
            },
        }),
        jobAlertPageData: builder.query({
            query: (body) => {
                return {
                    'url': `/exampleprovider/job-alert-page/`,
                }
            },
        })
    })
})

export const {
    useJobAlertMutation,
    useJobAlertPageDataQuery,
    useJobAlertFrequencyOptionQuery,
} = jobAlertApi 