import React from 'react';
import { useState, useEffect } from "react";
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateJobMutation, useGetSingleJobForEditQuery } from '../api/jobCreateApiSlice'

import * as Yup from 'yup'
import { LgbtToast } from '../../../components/LgbtToast/LgbtToast';
import { toast } from 'react-toastify';
import useJobUrl from '../../../common/hooks/useJobUrl';

function useJobUpdateForm() {

    const jobUrl = useJobUrl();
    const navigate = useNavigate();
    const { jobId } = useParams();
    const formRef = React.useRef(null);

    const [errorMessage, setErrorMessage] = useState("");
    const [currentToastId, setCurrentToastId] = useState(null);
    const
        {
            data: jobData,
            isLoading: isJobLoading,
            isFetching: isJobFetching,
            isError: isJobError
        } = useGetSingleJobForEditQuery(jobId);
    const [
        updateJob,
        {
            data: updatedData,
            isFetching: isUpdateFetching,
            isLoading: isUpdateLoading,
            isError: isUpdateError,
            error: updateError
        }
    ] = useUpdateJobMutation();

    const initialValues = {
        title: "",
        description: "",
        location_string: "",
        contract_types: [],
        salary_amount: 0,
        maximum_salary: 0,
        salary_type: 0,
        salary_text: null,
        sectors: [],
        apply_link: "",
        applyLinkType: 'url',
        is_remote_job: false,

        status: 1,
        skills: [],
        expiry_date: null,
        show_on_lgbtjobscouk: false,
        show_on_disabilityjobcouk: false,
        show_on_bmejobscouk: false,
    };


    const validationSchema = Yup.object({
        'title': Yup.string().required('Required').max(100, 'Max 100 characters'),
        'description': Yup.string().max(65535, 'Max 65535 characters'),
        'location_string': Yup.string(),
        'applyLinkType': Yup.string(),
        'apply_link': Yup.string()
            .when('applyLinkType', {
                is: 'url',
                then: () => Yup.string().url('Please enter a valid URL').required('URL is required'),
                otherwise: () => Yup.string().email('Please enter a valid email address').required('Email is required'),
            }),
        'contract_types': Yup.array(),
        'skills': Yup.array()
            .of(Yup.mixed().test(
                'isValidValue',
                'Invalid Skill',
                value => typeof value === 'number' || typeof value === 'string'
            )),
        'salary_text': Yup.string().optional().nullable(),
        'salary_amount': Yup.number().optional(),
        'maximum_salary': Yup.number().optional(),
        'salary_type': Yup.number().optional().nullable(),
        'expiry_date': Yup.date()
            .min(new Date(), 'Expiry date must be in the future').optional().nullable(),
        'sectors': Yup.array(),
        'show_on_lgbtjobscouk': Yup.boolean().optional(),
        'show_on_disabilityjobcouk': Yup.boolean().optional(),
        'show_on_bmejobscouk': Yup.boolean().optional(),
        'is_remote_job': Yup.boolean().optional(),
    })


    const jobUpdateForm = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, bag) => {
            // changing the date format to ISOString
            if (values.expiry_date instanceof Date)
                values.expiry_date = values.expiry_date.toLocaleDateString('en-CA');
            // creating salary object
            values.salary = {
                title: values.salary_text,
                amount: values.salary_amount !== null ? parseFloat(values.salary_amount) || null : null,
                maximum_salary: values.maximum_salary !== null ? parseFloat(values.maximum_salary) || null : null,
                salary_type: values.salary_type
            }

            if (currentToastId) {
                toast.dismiss(currentToastId);
            }

            const toastId = LgbtToast({ message: 'Posting job...', type: 'info' })
            setCurrentToastId(toastId);

            let jobUpdatedData = await updateJob({ body: values, slug: jobData.slug });
            if (jobUpdatedData.error) {
                const Error = () => (<>Failed updating the job</>)
                setErrorMessage(jobUpdatedData.error.data.detail);
                const toastId = LgbtToast({ message: (<Error />), type: 'error' })
                setCurrentToastId(toastId);
                return false;
            }

            const Success = () => (<>Job posted successfully! <a target='_blank' rel="noreferrer" style={{ color: "#365BB8" }} href={jobUrl(jobUpdatedData.data.slug)}>View</a></>)
            setErrorMessage('')
            const nextToastId = LgbtToast({ message: (<Success />), type: 'success' })
            setCurrentToastId(nextToastId);
            bag.resetForm();
            if (jobUpdatedData.data.status === 5) {
                navigate(`/job/preview/?slug=${jobUpdatedData.data.slug}&timestamp=${jobUpdatedData.data.timestamp}`, { replace: true })
            } else {
                navigate('/dashboard', { replace: true })
            }
        }
    })
    useEffect(() => {
        if (!isJobLoading && !isJobFetching && !isJobError && jobData) {
            if (!formRef.current) {

                let linkType = 'url', apply_link = jobData.apply_link;
                if (jobData.apply_link && jobData.apply_link.includes('@')) {
                    linkType = 'email';
                    apply_link = jobData.apply_link.replace('mailto:', '');
                }

                jobUpdateForm.setValues({
                    title: jobData.title,
                    description: jobData.description,
                    contract_types: jobData.contract_types,
                    location_string: jobData?.location_string || '',
                    salary_amount: parseFloat(jobData.salary?.amount) || 0,
                    maximum_salary: parseFloat(jobData.salary?.maximum_salary) || 0,
                    salary_type: jobData.salary?.salary_type || null,
                    salary_text: jobData.salary?.title || '',
                    sectors: jobData.sectors,
                    apply_link: apply_link,
                    applyLinkType: linkType, // Adjust based on your actual condition
                    status: jobData.status,
                    skills: jobData.skills || [], // Assuming skills is an array
                    expiry_date: jobData.expiry_date ? new Date(jobData.expiry_date) : null,
                    show_on_lgbtjobscouk: jobData?.show_on_lgbtjobscouk || false,
                    show_on_disabilityjobcouk: jobData?.show_on_disabilityjobcouk || false,
                    show_on_bmejobscouk: jobData?.show_on_bmejobscouk || false,
                    is_remote_job: jobData?.is_remote_job || false,
                })
                formRef.current = true;
            }
        }
    }, [jobData, isJobLoading, isJobFetching, isJobError]);
    return { jobUpdateForm, isJobLoading, isJobFetching, isJobError, errorMessage }
}

export default useJobUpdateForm