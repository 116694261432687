import Select from 'react-select';
import FieldError from '../../../JobPost/components/FieldError/FieldError';
import { useGetContractTypesQuery } from '../../../JobPost/api/jobCreateApiSlice';
import { useState } from 'react';

function JobTypeFieldsMultiple({ form }) {
    const { data: contractTypes, error, isError, isLoading } = useGetContractTypesQuery();
    const [selectedContractTypes, setSelectedContractTypes] = useState([]);

    const handleChange = (selectedOptions) => {
        setSelectedContractTypes(selectedOptions);
        const selectedValues = selectedOptions.map(option => option.value);
        form.setFieldValue('contract_type', selectedValues);
    };
    return (
        <section className="jobTypeFieldsWrap fieldGroupWrap">
            <div className="row">
                <div className="fieldHead col-md-3">
                    <h2>Job type</h2>
                </div>
                <div className="jobFields col-md-9">
                    <div className="row">
                        <div className="col-12">
                            <div className="fieldWrap">
                                <div className="labelBox">
                                    <label htmlFor="contract-type">Contract type</label>
                                </div>
                                <Select
                                    isMulti
                                    isClearable={false}
                                    isSearchable={false}
                                    value={selectedContractTypes}
                                    onChange={handleChange}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    placeholder="Select..."
                                    options={contractTypes || []}
                                />
                                <FieldError error={form.errors.contract_type} touched={form.touched.contract_type} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default JobTypeFieldsMultiple;
